import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import SimpleReactValidator from "simple-react-validator";
import { Link, Redirect, useHistory } from "react-router-dom";
import { login } from "./../Store/Action/Login";
import { FastForwardOutlined } from "@ant-design/icons";
const mapStateToProps = (state) => ({});

const Login1 = ({ login }) => {
  const history = useHistory();
  const [Auth, SetAuth] = useState(false);
  const [AddFormSubmit, setAddFormSubmit] = useState(false);
  const [captchaError, SetcaptchaError] = useState(false);
  const [LoginData, setLoginData] = useState({
    userName: "",
    password: "",
    groupName: "",
    captcha: "",
  });
  const [message, setMessage] = useState("");
  const [txt, setTxt] = useState("");
  const validator = useRef(new SimpleReactValidator());

  useEffect(() => {
    loadCaptchaEnginge(4, "white", "black", "numbers");
  }, []);

  useEffect(() => {
    validator.current.showMessages();
  }, []);

  useEffect(() => {
    SetAuth(localStorage.getItem("isAuthenticated"));
  }, []);

  const submitLogin = (e) => {
    e.preventDefault();
    setAddFormSubmit(true);
    SetcaptchaError(false);
    if (validateCaptcha(LoginData.captcha) === true) {
      if (validator.current.allValid()) {
        login({
          payload: LoginData,
          navigate: history,
        });

        // e.target.reset();
        // setTxt(e.target.reset())
      }
    } else {
      SetcaptchaError(true);
    }
  };

  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[0-9a-z]+$/;
    if (value === "" || re.test(value)) {
      setLoginData({
        ...LoginData,
        groupName: e.target.value,
      });
      setTxt(value);
    }
  };

  return Auth && Auth ? (
    <Redirect to="/home" />
  ) : (
    <Fragment>
      <body>
        <div className="main-wrapper">
          <div className="row auth-wrapper gx-0 ">
            <div className="col-lg-4 col-xl-3 bg-grad auth-box-2 on-sidebar order-last">
              <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="row justify-content-center text-center">
                  <div className="col-md-7 col-lg-12 col-xl-9">
                    <div>
                      <span className="db">
                        <img
                        className="w-75"
                          src="logo-icon.png"
                          alt="logo"
                        />
                      </span>
                   
                    </div>
                    <h2 className="text-white mt-4 fw-light">
                    Lorem
                      <span className="font-weight-medium">
                      ipsum dolor sit
                      </span>{" "}
                      tempor incididunt
                    </h2>
                    <p className="op-5 text-white fs-4 mt-4">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="loginform"
              className="
        col-lg-8 col-xl-9
        d-flex
        align-items-center
        justify-content-center
      "
            >
            
              <div className="row justify-content-center w-100 mt-4 mt-lg-0">
                <div className="col-lg-6 col-xl-3 col-md-7">
                <div className="card border-0" >
            <div className="card-body p-0 ">
              <h2>Login Form</h2>
              <p className="text-muted fs-4">
                Enter given details of your account
              </p>
                  <form
                    className="form-horizontal  needs-validation"
                    onSubmit={submitLogin}
                  >
                    <div className="form-floating mb-3">
                      
                        <input
                       
                          className="form-control form-input-bg rounded-pill"
                          autoFocus
                          type="text"
                          required=""
                          placeholder="Username"
                          onChange={(e) => {
                            setLoginData({
                              ...LoginData,
                              userName: e.target.value,
                            });
                          }}
                        />
                         <label for="tb-email">Username</label>
                        {validator.current.message(
                          "userName",
                          LoginData.userName,
                          ["required", `max:50`],
                          {
                            className: `invalid-feedback ${
                              AddFormSubmit ? "show" : "hide"
                            }`,
                            messages: {
                              required: "Enter Username",
                              max: "Maximum character is 50 only",
                            },
                          }
                        )}
                      
                    </div>

                    <div className="form-floating mb-3">
                     
                        <input
                          className="form-control form-input-bg rounded-pill"
                          type="password"
                          required=""
                          placeholder="Password"
                          onChange={(e) => {
                            setLoginData({
                              ...LoginData,
                              password: e.target.value,
                            });
                          }}
                        />
                         <label for="tb-email">Password</label>
                        {validator.current.message(
                          "password",
                          LoginData.password,
                          ["required", `max:20`],
                          {
                            className: `invalid-feedback ${
                              AddFormSubmit ? "show" : "hide"
                            }`,
                            messages: {
                              required: "Enter Password",
                              max: "Maximum character is 20 only",
                            },
                          }
                        )}
                      
                    </div>

                    <div className="form-floating mb-3">
                     
                        <input
                          className="form-control form-input-bg rounded-pill"
                          type="text"
                          placeholder="Group Short Name"
                          value={txt}
                          onChange={onInputChange}
                          required=""
                        />
                          <label for="tb-email">Group Short Name</label>
                        {validator.current.message(
                          "groupName",
                          LoginData.groupName,
                          ["required", `max:50`],
                          {
                            className: `invalid-feedback ${
                              AddFormSubmit ? "show" : "hide"
                            }`,
                            messages: {
                              required: "Enter Group Short Name",
                              max: "Maximum character is 50 only",
                            },
                          }
                        )}
                   
                    </div>

                    <div className="form-floating mb-3 row mx-1">
                    
                        <div className="col-5 mt-6 px-0">
                          <LoadCanvasTemplate  />
                        </div>
                   
                     <div className="col-7 px-0">
                     <input
                        className="form-control form-input-bg rounded-pill"
                        placeholder="Enter Captcha"
                        maxLength={4}
                        onChange={(e) => {
                          setLoginData({
                            ...LoginData,
                            captcha: e.target.value,
                          });
                        }}
                      />

                      {validator.current.message(
                        "groupName",
                        LoginData.captcha,
                        "required",
                        {
                          className: `invalid-feedback ${
                            AddFormSubmit ? "show" : "hide"
                          }`,
                          messages: {
                            required: "Enter Captcha",
                          },
                        }
                      )}
                     </div>
                    </div>
                    {LoginData.captcha != "" && captchaError == true ? (
                      <span style={{ color: "red" }}>Invalid Captcha</span>
                    ) : null}
                    <div className="form-group mb-3">
                      <div className="d-flex align-items-center mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-color btn-lg px-4 w-50 me-3 rounded-pill border-0"
                        onClick={submitLogin}
                      >
                       
                        Login
                      </button>
                        <div className="ms-auto">
                          <Link
                            to="/Forgotpassword1"
                            id="to"
                            className="fw-bold"
                          >
                          Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>

      
                  </form>
                  </div>
                  </div>
                </div>
                <footer className="footer bg-transparent  pt-0 pb-0">
            2022© Aparajitha Software Services Private Limited
          </footer>
          <footer className="footer bg-transparent  pb-1 pt-2">
            <span className="ms-4">
              Privacy Policy · Terms & Condition{" "}
            </span>
          </footer>
              </div>
              
            </div>
          </div>

       
        </div>
      </body>
    </Fragment>
  );
};

export default connect(mapStateToProps, {
  login,
})(Login1);
