import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import { Modal, Input, Table, Button, Space, Tooltip, DatePicker, Statistic } from 'antd';
import { providerlist, addprovider, updatestatus, updateblock, updateprovider } from './../../Store/Action/Master/Serviceprovider';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import {
    LikeOutlined, DislikeOutlined, PlayCircleOutlined,
    PlusOutlined, InfoCircleOutlined, StopOutlined, SearchOutlined, PlusCircleOutlined, CheckOutlined,
} from '@ant-design/icons';
import _, { merge } from 'lodash'




const mapStateToProps = (state) => ({
    lists: state.Serviceprovider.list.service_providers,
    filterrecall: state.recallfilters.recallfilters
})
const Serviceproviderlist = ({
    providerlist,
    lists,
    addprovider,
    updatestatus,
    updateblock,
    updateprovider,
    GetRemarksData,
    filterrecall: { recallremarks },
}) => {
    const { TextArea } = Input;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const location = useLocation();
    const paramid = location.pathname;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [modalVisible, SetmodalVisible] = useState(false);
    const [passvisible, Setpassvisible] = useState(false);
    const [blockvisible, Setblockvisible] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [remarksvalue, Setremarksvalue] = useState();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [remarksModal, setRemarksModal] = useState(false);
    const [remarks, setRemarks] = useState('')
    const [page, setPage] = useState(1);
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');

    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    })

    const [blockdata, Setblockdata] = useState({
        "sp_id": '',
        "is_blocked": '',
        "remarks": '',
        "password": ''
    })

    const modalcancel = () => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: '',
            contact_no1: '',
            contact_no2: '',
            contact_no: '',
            short_name: '',
            mobile_no: '',
            mobile_no1: '',
            Contract_from: '',
            Contract_to: '',
            contact_person: '',
            email: '',
            address: '',
        })
        setAddFormSubmit(false);
        SetmodalVisible(false);
    }

    const addopen = () => {
        SetmodalVisible(true);
    }

    const passwordcancel = () => {
        setAddFormSubmit1(false)
        Setpassvisible(false);
        Setpassword({
            ...passwordata,
            passvalue: ""

        })
    }

    const blockcancel = () => {
        Setblockvisible(false);
        Setblockdata({
            ...blockdata,
            password: '',
            remarks: ''

        })
        setAddFormSubmit2(false);

    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const Activate = () => {

        Swal.fire({
            title: 'Are You Sure?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
            confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Setpassvisible(true);


                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };
    const Block = () => {

        Swal.fire({
            title: 'Are You Sure?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
            confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Setblockvisible(true);


                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const [paginationSize, setPaginationSize] = useState(25);
    const columns = [
        {
            title: '#',
            key: 'index',
            align: "center",
            width: "50px",
            render: (text, record, index) => {
                return (
                    (page - 1) * paginationSize + index + 1
                )
            },
        },
        {
            title: 'Service Provider',
            dataIndex: 's_p_name',
            key: 's_p_name',
            ...getColumnSearchProps('s_p_name', 'Service Provider'),
            ellipsis: true,
            width: '165px',
            filteredValue: filteredInfo.s_p_name || null,
            sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: 'Contact Person',
            dataIndex: 'cont_person',
            key: 'cont_person',
            ...getColumnSearchProps('cont_person', 'Contact Person'),
            ellipsis: true,
            width: '150px',
            filteredValue: filteredInfo.cont_person || null,
            sorter: (a, b) => a.cont_person.localeCompare(b.cont_person),
            sortOrder: sortedInfo.columnKey === 'cont_person' ? sortedInfo.order : null,

        },
        {
            title: 'Contact No',
            dataIndex: 'cont_no',
            key: 'cont_no',
            ...getColumnSearchProps('cont_no', 'Contact No'),
            ellipsis: true,
            width: '140px',
            filteredValue: filteredInfo.cont_no || null,
            sorter: (a, b) => a.cont_no.length - b.cont_no.length,
            sortOrder: sortedInfo.columnKey === 'cont_no' ? sortedInfo.order : null,

        },
        {
            title: 'Email ID',
            dataIndex: 'e_id',
            key: 'e_id',
            ...getColumnSearchProps('e_id', 'Email ID'),
            ellipsis: true,
            width: '170px',
            filteredValue: filteredInfo.e_id || null,
            sorter: (a, b) => a.e_id.localeCompare(b.e_id),
            sortOrder: sortedInfo.columnKey === 'e_id' ? sortedInfo.order : null,


        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            ...getColumnSearchProps('remarks', 'Remarks'),
            ellipsis: true,
            width: '100px',
            filteredValue: filteredInfo.remarks || null,
            // sorter: (a, b) => a.remarks.length - b.remarks.length,

        },
        {
            title: <><label>Actions</label><Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"This Action Includes Edit, Active/Inactive Status Change, Block/Unblock Status Change"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_active',
            width: '110px',
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],
            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active == value,
            // onFilter: (value, record) => record.is_active === value,
            filterSearch: false,
            render: (text, record) => {

                return (
                    <Fragment>
                        {/* <div style={{display:'flex',justifyContent:'space-around'}}> */}
                        <div id="container-floatings" style={{ position: 'relative' }}>
                            <div className="nd4 nds">
                                {/* <img class="reminder"> */}
                                {record.is_blocked === true ?
                                    <div className='col-md-2'>
                                        <span
                                            className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { blockprovider(record) }}>
                                            <Tooltip title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                        </span>
                                    </div> :
                                    <div className='col-md-2'>
                                        <span
                                            className="btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { blockprovider(record) }}>
                                            <Tooltip title="Click here to block" color='#39cb7f'><i className="ri-check-line"></i></Tooltip>
                                        </span>
                                    </div>
                                }

                            </div>

                            <div className="nd3 nds">
                                {record.is_blocked == false ?
                                    <div>
                                        {record.is_active === true ?
                                            <span className="btn btn-sm btn-light-success
                                            text-success btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Deactivate" color='#39cb7f'><LikeOutlined /></Tooltip></span>
                                            :
                                            <span className="btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Activate" color='#fc4b6c'><DislikeOutlined /></Tooltip></span>}
                                    </div>
                                    :
                                    <div>
                                        {
                                            record.is_active === true ?
                                                <span className="btn btn-sm btn-light-success
                                                text-success btn-circle d-flex align-items-center justify-content-center" style={{ cursor: 'not-allowed' }}
                                                // onClick={(e) => { statuschange(row) }}
                                                ><Tooltip color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="btn btn-sm btn-light-danger
                                                text-danger btn-circle d-flex align-items-center justify-content-center" style={{ cursor: 'not-allowed' }}
                                                //  onClick={(e) => { statuschange(row) }}
                                                ><Tooltip color='#fc4b6c'><DislikeOutlined /></Tooltip></span>
                                        }
                                    </div>
                                }
                            </div>

                            <div className="nd1 nds">
                                {record.is_blocked == false ?
                                    <div className='col-md-2'>
                                        <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                                            onClick={(e) => { editservice(record) }}>
                                            <Tooltip title="Click here to Edit" color='#39cb7f'>
                                                <i className="ri-edit-line"></i></Tooltip>
                                        </span>
                                    </div>
                                    :
                                    <div className='col-md-2'>
                                        <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"

                                        ><Tooltip color='#39cb7f'>
                                                <i className="ri-edit-line" style={{ cursor: 'not-allowed' }}></i></Tooltip>
                                        </span>
                                    </div>
                                }
                            </div>

                            <div id="floating-button">
                                <p className="plus">+</p>
                                {/* <img class="edit" src="https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/bt_compose2_1x.png"> */}
                            </div>
                        </div>
                    </Fragment>
                )

            }
        },
    ];

    const [serviceProviderDetails, setServiceProviderDetails] = useState({
        provider_name: '',
        contact_no1: '',
        contact_no2: '',
        contact_no: '',
        short_name: '',
        mobile_no: '',
        mobile_no1: '',
        Contract_from: '',
        Contract_to: '',
        contact_person: '',
        email: '',
        address: '',
    });
    console.log(serviceProviderDetails, 'serviceProviderDetails');
    const [sid, Setsid] = useState({
        s_id: ''
    })


    const serviceProviderOnChange = (e) => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            [e.target.name]: e.target.value
        })
    }



    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviders",
                        {}
                    ]
                }
            ]

            providerlist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        formValidator2.current.showMessages()


    }, []);

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    const addservice = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            if (sid.s_id === '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveServiceProvider",
                            {
                                "s_p_name": serviceProviderDetails.provider_name,
                                "s_p_short": serviceProviderDetails.short_name,
                                "cont_from": moment(serviceProviderDetails.Contract_from).format('D-MMM-YYYY'),
                                "cont_to": moment(serviceProviderDetails.Contract_to).format('D-MMM-YYYY'),
                                "cont_person": serviceProviderDetails.contact_person,
                                "cont_no": serviceProviderDetails.contact_no1 + '-' + serviceProviderDetails.contact_no2 + '-' + serviceProviderDetails.contact_no,
                                "mob_no": serviceProviderDetails.mobile_no1 + '-' + serviceProviderDetails.mobile_no,
                                "e_id": serviceProviderDetails.email,
                                "address": serviceProviderDetails.address
                            }
                        ]
                    }
                ];
                addprovider({
                    payload: payload,
                    paramid: paramid
                })
            }
            else {

                const pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateServiceProvider",
                            {
                                "s_p_id": sid.s_id,
                                "s_p_name": serviceProviderDetails.provider_name,
                                "s_p_short": serviceProviderDetails.short_name,
                                "address": serviceProviderDetails.address,
                                "cont_from": moment(serviceProviderDetails.Contract_from).format('D-MMM-YYYY'),
                                "cont_to": moment(serviceProviderDetails.Contract_to).format('D-MMM-YYYY'),
                                "cont_person": serviceProviderDetails.contact_person,
                                "cont_no": serviceProviderDetails.contact_no1 + '-' + serviceProviderDetails.contact_no2 + '-' + serviceProviderDetails.contact_no,
                                "mob_no": serviceProviderDetails.mobile_no1 + '-' + serviceProviderDetails.mobile_no,
                                "e_id": serviceProviderDetails.email
                            }
                        ]
                    }
                ]
                updateprovider({
                    payload: pay,
                    paramid: paramid
                })
            }
        }
    }

    const statuschange = (e) => {
        Activate()
        Setpassword({
            ...passwordata,
            sp_id: e.s_p_id,
            active_status: !e.is_active
        })
    }

    const blockprovider = (e) => {
        Block()
        Setblockdata({
            ...blockdata,
            sp_id: e.s_p_id,
            is_blocked: !e.is_blocked
        })
    }

    const submitpassword = () => {
        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ChangeServiceProviderStatus",
                        {
                            "sp_id": passwordata.sp_id,
                            "active_status": passwordata.active_status,
                            "password": passwordata.passvalue
                        }
                    ]
                }
            ]
            updatestatus({
                payload: pay,
                paramid: paramid
            })
        }
    }

    const blockservice = () => {
        setAddFormSubmit2(true)
        if (formValidator2.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "BlockServiceProvider",
                        {
                            "sp_id": blockdata.sp_id,
                            "is_blocked": blockdata.is_blocked,
                            "remarks": blockdata.remarks,
                            "password": blockdata.password
                        }
                    ]
                }
            ]
            updateblock({
                payload: pay,
                paramid: paramid
            })
            // Setblockdata({
            //     ...blockdata,
            //     password: '',
            //     remarks: ''
            // })
        }
    }

    const editservice = (data) => {
        const phn = data.cont_no.split('-');
        const mbl = data.mob_no.split('-');
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: data.s_p_name,
            contact_no1: phn[0],
            contact_no2: phn[1],
            contact_no: phn[2],
            short_name: data.s_p_short,
            mobile_no: mbl[1],
            mobile_no1: mbl[0],
            Contract_from: data.cont_from,
            Contract_to: data.cont_to,
            contact_person: data.cont_person,
            email: data.e_id,
            address: data.address
        })
        Setsid({
            ...sid,
            s_id: data.s_p_id
        })
        SetmodalVisible(true);
    }

    const handleOk = () => {
        setRemarksModal(false)
    };

    const handleCancel = () => {
        setRemarksModal(false)
    };

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                Setblockdata({
                    ...blockdata,
                    remarks: record.r_description
                })
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    const [baseData, setBaseData] = useState([]);
    const [activeData, setActiveData] = useState('0')
    const [inActiveData, setInActiveData] = useState('0')
    const [blockedData, setBlockedData] = useState('0')
    const [unBlockedData, setUnBlockedData] = useState('0')
    // const [commonSearch, setCommonSearch] = useState();
    useEffect(() => {
        if (lists && lists.length > 0) {
            setBaseData(lists);
        }
    }, [lists])

    useEffect(() => {
        if (baseData && baseData.length > 0) {
            let array1 = _.filter(baseData, { is_active: true });
            console.log(array1, 'array1');
            setActiveData(array1.length)
            let array2 = _.filter(baseData, { is_active: false });
            setInActiveData(array2.length)
            let array3 = _.filter(baseData, { is_blocked: true });
            setBlockedData(array3.length)
            let array4 = _.filter(baseData, { is_blocked: false });
            setUnBlockedData(array4.length)
        }

    }, [baseData])

    console.log(baseData, 'baseData');


    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(lists)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = lists.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            setBaseData(filterTable)
        }


    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const onInputChange = e => {
        const { value } = e.target;


        const re = /^[0-9a-zA-Z]+$/;
        if (value === "" || re.test(value)) {
            setServiceProviderDetails({
                ...serviceProviderDetails,
                short_name: e.target.value
            })
        }
    }

    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span style={{ color: '#1890ff' }} className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Service Providers</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-8" style={{ textAlign: 'center' }}>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-3 px-1 unitActive" style={{ textAlign: 'center' }}>
                                            <Statistic
                                                value={activeData}
                                                prefix={
                                                    <div className='unitActive' style={{ marginRight: '2%' }}>
                                                        <span className="btn btn-sm btn-success text-white btn-circle d-flex align-items-center justify-content-center"><Tooltip title="Active"><LikeOutlined /></Tooltip>
                                                        </span>
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3 px-1" style={{ textAlign: 'center' }}>
                                            <div className="row unitClosed">
                                                <Statistic style={{ marginLeft: '10%' }}
                                                    value={inActiveData}
                                                    prefix={
                                                        <div className='unitInActive'>
                                                            <span className="btn btn-sm btn-danger text-white btn-circle d-flex align-items-center justify-content-center">
                                                                <Tooltip title="In-Active"><DislikeOutlined /></Tooltip> </span></div>
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 px-1 unitActive" style={{ textAlign: 'center' }}>
                                            <Statistic
                                                value={unBlockedData}
                                                prefix={
                                                    <div className='unitActive'>
                                                        <span className="btn btn-sm btn-success text-white btn-circle d-flex align-items-center justify-content-center">
                                                            <Tooltip title="Un-Blocked"><CheckOutlined /></Tooltip> </span></div>
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3 px-1 unitClosed" style={{ textAlign: 'center' }}>
                                            <Statistic
                                                value={blockedData}
                                                prefix={
                                                    <div className='unitInActive'>
                                                        <span className="btn btn-sm btn-danger text-white btn-circle d-flex align-items-center justify-content-center">
                                                            <Tooltip title="Blocked"><StopOutlined /></Tooltip> </span></div>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/*

                <div className='unitActive'>



                  <Statistic title="" style={{ marginLeft: '30%' }} value={10} valueStyle={{ marginLeft: '20%' }}

                    prefix={

                      <div className='unitActive' style={{ marginRight: '2%' }}>

                        <span

                          className=" btn btn-m btn-danger text-white btn-circle d-flex align-items-center justify-content-center">

                          <FeatherIcon icon="check-square" />

                        </span></div>} />

                </div>




                <div className='unitInActive'>



                  <Statistic title="" style={{ marginLeft: '30%' }} value={5} prefix={

                    <div className='unitInActive'>

                      <span

                        className=" btn btn-m btn-warning text-white btn-circle d-flex align-items-center justify-content-center">

                        <FeatherIcon icon="clipboard" />

                      </span></div>} />



                </div>




                <div className='unitClosed'>



                  <Statistic title="" style={{ marginLeft: '30%' }} value={3} prefix={

                    <div className='unitClosed'>

                      <span

                        className=" btn btn-m btn-danger

                                        text-white btn-circle d-flex

                                        align-items-center justify-content-center">

                        <FeatherIcon icon="lock" />

                      </span></div>} />



                </div>




                <div className='unitClosed'>



                  <Statistic title="" style={{ marginLeft: '30%' }} value={3} prefix={

                    <div className='unitClosed'>

                      <span

                        className="btn btn-m btn-success text-white btn-circle d-flex align-items-center justify-content-center">

                        <FeatherIcon icon="unlock" />

                      </span></div>} />



                </div> */}
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">

                                <div className="search-box" style={{ position: 'relative', marginRight: '14.2%',borderRadius:'1px' }}>
                                    <input className="search-txt" type="text" value={clearText} onChange={searchRecords} placeholder="Type to Search" />
                                    <a className="search-btn">
                                        {iconEnable == true ?
                                            <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                            : <i className="fas fa-search"></i>}
                                    </a>
                                </div>
                                {/* <div>
                                    <Tooltip title="Add Service Provider" color='#1890ff'><Button type="primary" shape="round" className='addbutton' style={{marginTop:'1%'}}
                                        icon={<PlusCircleOutlined />} size='default' onClick={addopen} >
                                        Add
                                    </Button></Tooltip></div> */}
                                <div className="wrapper" style={{ marginRight: '0px' }}>

                                    <button onClick={addopen} className="icons twitter" style={{ border: 'none', margin: '0px',borderRadius:'1px' }}>
                                        <span className="tooltips" style={{ right: '105%' }}>Add</span>
                                        <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-0">
                        <div className="row" >
                            <div className="col-12">
                                <div className="card rounded">
                                    <div className='card-body' style={{ padding: '0px' }}>
                                        <Table
                                            sortDirections={["ascend", "descend", "ascend"]}
                                            className={localStorage.getItem("currentTheme")}
                                            id='service-user-priv'
                                            size={'small'}
                                            columns={columns}
                                            dataSource={baseData}
                                            bordered
                                            pagination={false}
                                            showSorterTooltip={false}
                                            onChange={handleChange} />
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div>
            <div className=''>
                <Modal title="Service Provider" visible={modalVisible} footer={null} className={"modalCurve serviceProvider user-priv-modal add-service-prv " + localStorage.getItem('currentTheme')}
                    onCancel={modalcancel}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label className="control-label"><b>Service Provider</b></label>
                                        <span style={{ "color": "red" }}>*</span>
                                        <Input
                                            type="text"
                                            id="provider_name"
                                            name='provider_name'
                                            placeholder="Enter Service Provider Name"
                                            autoComplete={'off'}
                                            onChange={serviceProviderOnChange}
                                            style={{ marginTop: '5px' }}
                                            value={serviceProviderDetails.provider_name}
                                            maxlength='50'
                                            onKeyPress={(event) => {
                                                if (!/[a-zA-Z ]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        {formValidator.current.message(
                                            'provider_name',
                                            serviceProviderDetails.provider_name,
                                            [
                                                'required',
                                                {
                                                    regex: /^[a-zA-Z ]*$/,
                                                },

                                                `max:50`
                                            ],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Service Provider Name Required',
                                                    regex: 'Enter Valid Name',
                                                    max: 'Maximum character is 50 only'
                                                }
                                            })}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label className="control-label"><b>Short Name</b></label>
                                        <span style={{ "color": "red" }}>*</span>
                                        <Input
                                            type="text"
                                            name='short_name'
                                            id="short_name"
                                            style={{ marginTop: '5px' }}
                                            className="form-control"
                                            placeholder="Enter Short Name"
                                            autoComplete={'off'}
                                            onChange={onInputChange}
                                            value={serviceProviderDetails.short_name}
                                            maxlength='25'
                                        />

                                        {formValidator.current.message(
                                            'short_name',
                                            serviceProviderDetails.short_name,
                                            [
                                                'required',
                                                {
                                                    regex: /^[a-zA-Z0-9]*$/,
                                                },

                                                `max:25`
                                            ],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Short Name Required',
                                                    regex: 'Enter Valid Name',
                                                    max: 'Maximum character is 25 only'
                                                }
                                            })}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label className="control-label"><b>Contact Number</b></label>
                                        <span style={{ "color": "red" }}>*</span>
                                        <div className="row">
                                            <div className="col-3 pe-0">
                                                <Input
                                                    style={{ marginTop: '5px' }}
                                                    type="text"
                                                    name='contact_no1'
                                                    placeholder="+00"
                                                    autoComplete={'off'}
                                                    onChange={serviceProviderOnChange}
                                                    value={serviceProviderDetails.contact_no1}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9 +]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxlength='4'
                                                /> {formValidator.current.message(
                                                    'contact_no1',
                                                    serviceProviderDetails.contact_no1,
                                                    ['required'],

                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Code Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className="col-3 pe-0">
                                                <Input
                                                    type="text"
                                                    name='contact_no2'
                                                    placeholder="000"
                                                    autoComplete={'off'}
                                                    onChange={serviceProviderOnChange}
                                                    value={serviceProviderDetails.contact_no2}
                                                    // onKeyUp={allowNum}
                                                    style={{ marginTop: '5px' }}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxlength='4'

                                                />
                                                {formValidator.current.message(
                                                    'contact_no2',
                                                    serviceProviderDetails.contact_no2,
                                                    ['required'],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Area Code Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className="col-6">
                                                <Input
                                                    type="text"
                                                    id="contact_no"
                                                    style={{ marginTop: '5px' }}
                                                    name='contact_no'
                                                    placeholder="1234567890"
                                                    onChange={serviceProviderOnChange}
                                                    autoComplete={'off'}
                                                    value={serviceProviderDetails.contact_no}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    minlength='6'
                                                    maxlength='10'
                                                />
                                                {formValidator.current.message(
                                                    'contact_no',
                                                    serviceProviderDetails.contact_no,
                                                    ['required', { regex: /^([0-9]{6}|[0-9]{7}|[0-9]{8}|[0-9]{9}|[0-9]{10})$/ }],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Contact Number Required',
                                                            regex: 'Invalid Contact Number'
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label className="control-label"><b>Mobile Number</b></label>

                                        <div className="row">
                                            <div className="col-3 pe-0">
                                                <Input
                                                    type="text"
                                                    name='mobile_no1'
                                                    autoComplete={'off'}
                                                    placeholder="+00"
                                                    style={{ marginTop: '5px' }}
                                                    onChange={serviceProviderOnChange}
                                                    value={serviceProviderDetails.mobile_no1}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9 +]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxlength='3'
                                                />

                                                {formValidator.current.message(
                                                    'mobile_no1',
                                                    serviceProviderDetails.mobile_no1,
                                                    [{ regex: /^\+?[0-9]{2}$/ }],

                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Mobile Number Required',
                                                            regex: 'Enter Valid Mobile Number'
                                                        }
                                                    })}

                                            </div>
                                            <div className="col-9">
                                                <Input
                                                    type="text"
                                                    id="contact_no"
                                                    name='mobile_no'
                                                    style={{ marginTop: '5px' }}
                                                    autoComplete={'off'}
                                                    placeholder="1234567890"
                                                    onChange={serviceProviderOnChange}
                                                    value={serviceProviderDetails.mobile_no}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxlength='10'
                                                />

                                                {formValidator.current.message(
                                                    'mobile_no1',
                                                    serviceProviderDetails.mobile_no1,
                                                    [{ regex: /^\+?[0-9]{2}$/ }],

                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            // required: 'Required',
                                                            regex: 'Valid Country Code Required'
                                                        }
                                                    })}

                                                {formValidator.current.message(
                                                    'mobile_no1',
                                                    serviceProviderDetails.mobile_no,
                                                    [

                                                        {
                                                            regex: /^\+?[1-9]{1}[0-9]{9}$/,
                                                        },
                                                        `min:10`,
                                                        `max:10`
                                                    ],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {

                                                            regex: 'Enter Valid Mobile Number  Only',
                                                            min: 'mobile number should be 10 digits',
                                                            max: 'mobile number should be 10 digits'
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label className="control-label"><b>Contract From</b></label>
                                        <span style={{ "color": "red" }}>*</span>
                                        {/* <Input
                                        type="date"
                                        id="Contract_from"
                                        name='Contract_from'
                                        autoComplete={'off'}
                                        placeholder="DD/MM/YYYY"
                                        onChange={(e) => {
                                            setServiceProviderDetails({
                                                ...serviceProviderDetails,
                                                Contract_from: e.target.value,
                                                Contract_to: ''
                                            })

                                        }}
                                        value={serviceProviderDetails.Contract_from}
                                        onKeyDown={(e) => e.preventDefault()}
                                    /> */}
                                        <DatePicker placeholder="DD-MMM-YYYY" className='form-control' type="date"
                                            format={'DD-MMM-YYYY'}
                                            allowClear={false}
                                            value={serviceProviderDetails.Contract_from != '' ? moment(serviceProviderDetails.Contract_from) : undefined}
                                            style={{ marginTop: '5px' }}
                                            onChange={(e) => {
                                                setServiceProviderDetails({
                                                    ...serviceProviderDetails,
                                                    Contract_from: e._d,
                                                    Contract_to: ''
                                                })

                                            }}
                                        ></DatePicker >
                                        {formValidator.current.message(
                                            'Contract_from',
                                            serviceProviderDetails.Contract_from,
                                            ['required'],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Contract From Required'
                                                }
                                            })}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label className="control-label"><b>Contract To</b></label>
                                        <span style={{ "color": "red" }}>*</span>
                                        {/* 
                                    <Input
                                        type="date"
                                        id="Contract_to"
                                        name='Contract_to'
                                        autoComplete={'off'}
                                        placeholder="DD/MM/YYYY"
                                        onChange={serviceProviderOnChange}
                                        value={serviceProviderDetails.Contract_to}
                                        min={serviceProviderDetails.Contract_from}
                                        onKeyDown={(e) => e.preventDefault()}
                                    /> */}
                                        <DatePicker placeholder="DD-MMM-YYYY" className='form-control' type="date"
                                            format={'DD-MMM-YYYY'}
                                            allowClear={false}
                                            style={{ marginTop: '5px' }}
                                            value={serviceProviderDetails.Contract_to != '' ? moment(serviceProviderDetails.Contract_to) : undefined}
                                            disabledDate={(current) => {
                                                return moment(serviceProviderDetails.Contract_from) >= current || moment() >= current
                                            }}
                                            onChange={(e) => {
                                                setServiceProviderDetails({
                                                    ...serviceProviderDetails,
                                                    Contract_to: e._d
                                                })
                                            }}
                                        ></DatePicker >
                                        {formValidator.current.message(
                                            'Contract_to',
                                            serviceProviderDetails.Contract_to,
                                            ['required'],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Contract To Required',

                                                }
                                            })}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label className="control-label"><b>Email ID</b></label>
                                        <span style={{ "color": "red" }}>*</span>
                                        <Input
                                            type="text"
                                            id="email"
                                            name='email'
                                            style={{ marginTop: '5px' }}
                                            autoComplete={'off'}
                                            placeholder="Enter Email ID"
                                            onChange={serviceProviderOnChange}
                                            value={serviceProviderDetails.email}
                                        />
                                        {formValidator.current.message(
                                            'email',
                                            serviceProviderDetails.email,
                                            ['required', { regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Email ID Required',
                                                    regex: 'Enter valid email'
                                                }
                                            })}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label className="control-label"><b>Contact Person</b></label>
                                        <span style={{ "color": "red" }}>*</span>
                                        <Input
                                            type="text"
                                            id="contact_person"
                                            name='contact_person'
                                            autoComplete={'off'}
                                            style={{ marginTop: '5px' }}
                                            placeholder="Enter Contact Person"
                                            onChange={serviceProviderOnChange}
                                            value={serviceProviderDetails.contact_person}
                                            onKeyPress={(event) => {
                                                if (!/[a-zA-Z ]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        {formValidator.current.message(
                                            'contact_person',
                                            serviceProviderDetails.contact_person,
                                            ['required', { regex: /^[a-zA-Z ]*$/ }],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Contact Person Name Required',
                                                    regex: 'Enter valid name'
                                                }
                                            })}

                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-2">
                                        <label><b>Address</b></label>
                                        <TextArea className="form-control" id="remarks" cols="3" rows="1"
                                            name='address' onChange={serviceProviderOnChange}
                                            style={{ marginTop: '5px' }}
                                            value={serviceProviderDetails.address} maxlength='500'></TextArea>
                                        {formValidator.current.message(
                                            'address',
                                            serviceProviderDetails.address,
                                            [
                                                `max:500`
                                            ],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    max: 'Maximum character is 500 only'
                                                }
                                            })}
                                    </div>
                                </div>
                            </div>

                            <div className="form-actions" style={{ textAlign: 'center', marginTop: '12px' }}>
                                {/* <Button type="primary" shape="round" className='addbutton'
                                style={{ background: "#198754", borderColor: "#198754", marginTop: '2%' }}
                                icon={<PlayCircleOutlined />} size='default'
                                onClick={addservice}
                            >
                                Submit
                            </Button> */}
                                {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={addservice}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                Submit
                            </div> */}
                                <button className="noselect viewicon-button addbutton" onClick={addservice} style={{ marginLeft: '40%', backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} ><span className='text'>Submit</span>
                                    <span style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} className="icon"><PlayCircleOutlined />
                                    </span>
                                </button>
                            </div>
                        </div>

                    </div>
                </Modal>
                <Modal visible={passvisible} className="newStyleModalPassword modal-service-priv" footer={null} onCancel={passwordcancel}>

                    <div style={{ marginTop: '5px' }}>
                        <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='passwordvalue'
                            placeholder="Enter password"
                            onChange={(e) => {
                                Setpassword({
                                    ...passwordata,
                                    passvalue: e.target.value

                                })
                            }}
                            value={passwordata.passvalue}
                            style={{ marginTop: "5px", width: '95%' }}
                            className="form-control"
                        />
                        {formValidator1.current.message(
                            'Password',
                            passwordata.passvalue,
                            ['required'],
                            {
                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required'
                                }
                            })}

                    </div>
                    <br />
                    <div className={"form-actions text-center " + localStorage.getItem("currentTheme")}>
                        {/* <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginBottom: '3%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={submitpassword}
                    >Submit
                    </Button> */}
                        {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={submitpassword}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submit
                    </div> */}
                        <button className="noselect viewicon-button addbutton" onClick={submitpassword} style={{ marginLeft: '27%',marginBottom:'3%', backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} ><span className='text'>Submit</span>
                            <span style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} className="icon"><PlayCircleOutlined />
                            </span>
                        </button>

                    </div>
                </Modal>
                <Modal visible={blockvisible} className='usr_modal_class user-priv-modal' footer={null}
                    onCancel={blockcancel}>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    })
                                }}
                                value={passwordata.passvalue}
                                style={{ marginTop: "5px", width: '95%' }}
                                className="form-control"
                            />
                            {formValidator1.current.message(
                                'Password',
                                passwordata.passvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}

                        </div>
                    </div>
                    <br />
                 
                </Modal>
            </div>
            <div className=''>
                <Modal visible={blockvisible} className='usr_modal_class user-priv-modal' footer={null}
                    onCancel={blockcancel}>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                className="form-control"
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setblockdata({
                                        ...blockdata,
                                        password: e.target.value
                                    })
                                }}
                                value={blockdata.password}
                                style={{width:'95%'}}
                            />
                            {formValidator2.current.message(
                                'Password',
                                blockdata.password,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}

                        </div>
                    </div>
                    <br />
                    <div className="col-md-12">
                        <div className="row">
                            <label htmlFor=""><b>Remarks:</b> <span style={{ "color": "red" }}>*</span></label></div>
                       
                        <div className="row">
                            <div className="d-flex align-items-end">
                                <TextArea name="remarks" className='form-control' placeholder='Enter Remarks'
                                    value={blockdata.remarks} id="remarks" cols="3" rows="1"
                                    onChange={(e) => {
                                        Setblockdata({
                                            ...blockdata,
                                            remarks: e.target.value
                                        })
                                    }}></TextArea>
                                <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                    onClick={() => { showRemarksModal() }}>
                                    <PlusCircleOutlined />
                                </span>
                            </div>
                            {formValidator2.current.message(
                                'remark',
                                blockdata.remarks,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Remarks Required'
                                    }
                                })}</div>
                       
                    </div>
                    <div className={"form-actions text-center " + localStorage.getItem('currentTheme')}>
                    
                        <button className="noselect viewicon-button addbutton" onClick={blockservice} style={{ marginLeft: '30%', marginTop: '8%', backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} ><span className='text'>Submit</span>
                            <span style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} className="icon"><PlayCircleOutlined />
                            </span>
                        </button>
                    </div>
                </Modal>
                <Modal title="Remarks List" footer={null} visible={remarksModal} className={"remark-header  add-service-prv " + localStorage.getItem('currentTheme')}
                    onOk={handleOk}
                    onCancel={handleCancel}>

                    <Table
                        size={'small'}
                        columns={RemarksColumn}
                        dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                        bordered
                        // pagination={{
                        //     defaultPageSize: dataTableProperties.pagesize,
                        //     showSizeChanger: dataTableProperties.sizechanger,
                        //     pageSizeOptions: dataTableProperties.pageSizeOptions
                        // }}
                        pagination={false}
                    />

                </Modal>
            </div>
        </Fragment>
    )
}

export default connect(mapStateToProps, {
    providerlist,
    addprovider,
    updatestatus,
    updateblock,
    updateprovider,
    GetRemarksData
})(Serviceproviderlist);