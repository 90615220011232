import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { forgotPassword } from '../Store/Action/Forgotpassword';
import { useLocation, useHistory, Link } from 'react-router-dom';
const mapStateToProps = (state) => ({

})

const Forgotpassword1 = ({
    forgotPassword
}) => {
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const history = useHistory();
    const [ForgotData, setForgotData] = useState({
        userId: '',
        groupName: ''
    })
    const validator = useRef(new SimpleReactValidator());

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    const submitForgot = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            forgotPassword({
                payload: ForgotData
            })
        }
    }
    return (
        <Fragment>
            {/* <div className="main-wrapper-bg  ">
                <div className="auth-wrapper d-flex no-block justify-content-center 
                align-items-center">
                    <div className="auth-box p-4 bg-white rounded m-0">
                        <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                        <div id="loginform">
                            <div className="logo">
                                <center>
                                    <h3 className="box-title mb-3">Forgot Password</h3>
                                </center>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form className="form-horizontal  needs-validation" id="loginform">
                                        <div className="form-floating mb-3">
                                            <div className="">
                                                <input className="form-control form-input-bg rounded-pill" type="text" required="" autoFocus={true} placeholder="User ID" onChange={(e) => {
                                                    setForgotData({
                                                        ...ForgotData, userId: e.target.value
                                                    })
                                                }} />
                                                 <label for="tb-email">Username</label>
                                                {validator.current.message(
                                                    'userId',
                                                    ForgotData.userId,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'User Id Required',
                                                        }
                                                    })}
                                            </div>

                                        </div>

                                        <div className="form-group mb-4">

                                            <div className="">

                                                <input className="form-control" type="text" required="" placeholder="Group Short Name" onChange={(e) => {
                                                    setForgotData({
                                                        ...ForgotData, groupName: e.target.value
                                                    })
                                                }} />
                                                {validator.current.message(
                                                    'groupName',
                                                    ForgotData.groupName,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Group Short Name Required',
                                                        }
                                                    })}
                                            </div>

                                        </div>



                                        <div className="col-md-12">
                                            <div className='row'>


                                                <div className="col-md-6">

                                                    <button type='button' className="

                                                        btn btn-primary

                                                        d-block

                                                        w-100

                                                        waves-effect waves-light

                                                        "
                                                        onClick={submitForgot}
                                                    >

                                                        Submit

                                                    </button>

                                                </div>

                                                <div className="col-md-6">

                                                    <button type='primery' className="

                                                        btn btn-primary

                                                        d-block

                                                        w-100

                                                        waves-effect waves-light

                                                        "
                                                        onClick={() => {
                                                            history.push('/login')
                                                        }}
                                                    >

                                                        Cancel

                                                    </button>

                                                </div>

                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <footer className="footer bg-transparent text-white">2022© Aparajitha Software Services Private Limited</footer>

            </div> */}
        <div className="main-wrapper">
          <div className="row auth-wrapper gx-0 ">
            <div className="col-lg-4 col-xl-3 bg-grad auth-box-2 on-sidebar order-last">
              <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="row justify-content-center text-center">
                  <div className="col-md-7 col-lg-12 col-xl-9">
                    <div>
                      <span className="db">
                        <img
                        className="w-75"
                          src="logo-icon.png"
                          alt="logo"
                        />
                      </span>
                   
                    </div>
                    <h2 className="text-white mt-4 fw-light">
                    Lorem
                      <span className="font-weight-medium">
                      ipsum dolor sit
                      </span>{" "}
                      tempor incididunt
                    </h2>
                    <p className="op-5 text-white fs-4 mt-4">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="loginform"
              className="
        col-lg-8 col-xl-9
        d-flex
        align-items-center
        justify-content-center
      "
            >
            
              <div className="row justify-content-center w-100 mt-4 mt-lg-0">
                <div className="col-lg-6 col-xl-3 col-md-7">
                <div className="logo">
                    <h3>Recover Password</h3>
                  </div>
                <form className="form-horizontal  needs-validation" >
                                        <div className="form-floating mb-3">
                                           
                                                <input className="form-control form-input-bg rounded-pill" type="text" required="" autoFocus={true} placeholder="User ID" onChange={(e) => {
                                                    setForgotData({
                                                        ...ForgotData, userId: e.target.value
                                                    })
                                                }} />
                                                 <label >User ID</label>
                                                {validator.current.message(
                                                    'userId',
                                                    ForgotData.userId,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'User Id Required',
                                                        }
                                                    })}
                                           

                                        </div>

                                        <div className="form-floating mb-3">

                                           

                                                <input className="form-control form-input-bg rounded-pill" type="text" required="" placeholder="Group Short Name" onChange={(e) => {
                                                    setForgotData({
                                                        ...ForgotData, groupName: e.target.value
                                                    })
                                                }} />
                                                 <label >Group Short Name</label>
                                                {validator.current.message(
                                                    'groupName',
                                                    ForgotData.groupName,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Group Short Name Required',
                                                        }
                                                    })}
                                           

                                        </div>



                                        <div className="col-md-12">
                                            <div className='row'>


                                                <div className="col-md-6">

                                                    <button type='button' className="

btn btn-primary btn-color btn-lg px-4 w-50 me-3 rounded-pill border-0 w-100

                                                        "
                                                        onClick={submitForgot}
                                                    >

                                                        Submit

                                                    </button>

                                                </div>

                                                <div className="col-md-6">

                                                    <button type='button' className="

btn btn-border-color btn-lg px-4 w-50 me-3 rounded-pill  w-100

                                                        "
                                                        onClick={() => {
                                                            history.push('/login1')
                                                        }}
                                                    >

                                                        Cancel

                                                    </button>

                                                </div>

                                            </div>
                                        </div>
                                    </form>
                
                </div>
        
              </div>
              
            </div>
          </div>

       
        </div>
        </Fragment>
    );
}

export default connect(mapStateToProps, {
    forgotPassword
})(Forgotpassword1);